import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useAxertaStore = defineStore("axerta", () => {
  // REFS
  const _dossier = ref(null);
  const _ordineDiServizio = ref(null);

  //COMPUTED
  const dossier = computed(() => {
    if (!_dossier.value)
      _dossier.value = JSON.parse(localStorage.getItem("AX_dossier"));
    return _dossier.value;
  });
  const ordineDiServizio = computed(() => {
    if (!_ordineDiServizio.value)
      setOrdineDiServizio(
        JSON.parse(localStorage.getItem("AX_ordineDiServizio"))
      );

    return _ordineDiServizio.value;
  });

  //FUNCTIONS
  function setDossier(dossier) {
    localStorage.setItem("AX_dossier", JSON.stringify(dossier));
    _dossier.value = dossier;
  }
  function setOrdineDiServizio(ordineDiServizio) {
    localStorage.setItem(
      "AX_ordineDiServizio",
      JSON.stringify(ordineDiServizio)
    );
    _ordineDiServizio.value = ordineDiServizio;
  }

  return {
    dossier,
    setDossier,

    ordineDiServizio,
    setOrdineDiServizio,
  };
});
